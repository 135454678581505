import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { PageContext } from "../context"

const ContactPage = ({ location }) => {
  return (
    <PageContext.Provider value={{location}} >
      <Layout>
        <SEO title="Contact me" />
        <h1>Contact Me</h1>
      </Layout>
    </PageContext.Provider>
  )
}

export default ContactPage
